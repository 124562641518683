import * as React from 'react';
import {Helmet} from 'react-helmet';
import {google, ics, office365} from 'calendar-link';

import {event} from '../assets/constants';
import anchorSvg from '../images/anchor.svg';

import favicon from '../images/wedding-favicon.png';
import {GoLocation} from '@react-icons/all-files/go/GoLocation';

// Style
const headingStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#f6f8f8',
  textTransform: 'uppercase',
  marginTop: '1em'
}

const headerDate = {
  fontFamily: 'Cochin',
  letterSpacing: '0.2em',
  fontSize: '0.7em',
  marginBottom: '1em'
}

const headerCity = headerDate;
const headerName = {
  fontSize: '1em',
  fontFamily: 'Circular Std',
  letterSpacing: '0.2em',
  marginBottom: '1em'
}

const footerText = {
  fontFamily: 'Circular Std',
  color: '#f6f8f8',
  textTransform: 'uppercase',
}

const anchorDivStyle: React.CSSProperties = {
  textAlign: 'center',
}

const anchorSvgStyle = {
  marginTop: '10px',
  width: '60px'
}

const mainStyle: React.CSSProperties = {
  marginTop: '4em'
}

const calendarLinks = [
  {
    link: google(event),
    text: 'Google Calendar',
  },
  {
    link: office365(event),
    text: 'Office 365',

  },
  {
    link: ics(event),
    text: 'Other',
  }
]


// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={favicon} sizes="32x32"/>
        <title>Mariage Mathilde et Joris</title>
        <body className="h-100"/>
        <html className="h-100"/>
      </Helmet>
      <main style={mainStyle} className="container">
        <div className="row" style={anchorDivStyle}>
          <div className="col">
            <img alt="anchor" style={anchorSvgStyle} src={anchorSvg}></img>
          </div>
        </div>
        <h1 style={headingStyle}>
            <span className="row justify-content-center" style={headerDate}>
              27-28 Mai 2023
            </span>
          <div className="w-100"></div>
          <span className="row justify-content-center" style={headerName}>
              Mathilde & Joris
            </span>
          <div className="w-100"></div>
          <span className="row justify-content-center" style={headerCity}>
              Saint-Briac-Sur-Mer
            </span>
        </h1>
        <div id="rsvp" className="container d-flex justify-content-center">
          <a className="btn btn-warning" href="https://lydia-app.com/pots?id=14361-mariage-mathilde-joris"
             target="_blank">
            Participation en ligne 💌
          </a>
        </div>
        <div className="add-to-calendar container d-flex justify-content-center">
          <div className="dropdown">
            <a className="btn btn-secondary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
               aria-expanded="false">
              Ajouter à mon calendrier
            </a>
            <ul className="dropdown-menu">
              {calendarLinks.map(calendarLink =>
                (<li><a className="dropdown-item" href={calendarLink.link}>{calendarLink.text}</a></li>)
              )}
            </ul>
          </div>
        </div>
        <div className="container hosting-wrapper">
          <a className="btn btn-light d-flex justify-content-center" data-bs-toggle="collapse" href="#collapseHosting"
             role="button"
             aria-expanded="false" aria-controls="collapseHosting">
            Programme ↓
          </a>
          <div className="collapse" id="collapseHosting">
            <div className="card hosting-options">
              <div className="card-body">
                <h5 className="card-title">💒 Cérémonie Religieuse</h5>
                <h6 className="card-subtitle mb-2 text-muted">16h30 - Eglise de Saint-Cast-Le-Guildo</h6>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item"><GoLocation/> <a href="https://goo.gl/maps/exvR4gLGQj8Vr3i67">4 Rue du
                    Chanoine Ribault, 22380 Saint-Cast-le-Guildo
                  </a></li>
                </ul>
              </div>
            </div>
            <div className="card hosting-options">
              <div className="card-body">
                <h5 className="card-title">🍾 La fête</h5>
                <h6 className="card-subtitle mb-2 text-muted">18h30 - Les Essarts</h6>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item"><GoLocation/> <a href="https://goo.gl/maps/2U7TX7p7VrowmVrp6">20 Rue
                    des Essarts, 35800 Saint-Briac-sur-Mer</a></li>
                </ul>
              </div>
            </div>
            <div className="card hosting-options">
              <div className="card-body">
                <h5 className="card-title">☀️ Le lendemain</h5>
                <h6 className="card-subtitle mb-2 text-muted">12h00 - Les Essarts</h6>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item"><GoLocation/> <a href="https://goo.gl/maps/2U7TX7p7VrowmVrp6">20 Rue
                    des Essarts, 35800 Saint-Briac-sur-Mer</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="mt-auto container-fluid">
      </footer>
    </>
  )
}

export default IndexPage
