export const event = {
  title: 'Mariage Mathilde et Joris',
  description: `
    Plus d'infos à venir sur https://math-jojo.com
  `,
  location: '35800 Saint-Briac-sur-Mer, France',
  start: '2023-05-27 16:30:00 +0200',
  end: '2023-05-28 17:00:00 +0200',
};

export const hostings = [
  {
    name: 'Appart Hotel Lagrange',
    category: 'Appart Hôtel ***',
    address: '5 Rue du Clos de la Fontaine 35800 Saint Briac sur Mer',
    phone: '04 26 70 80 45',
    distance_km: '950m',
    distance_time: '4 minutes',
    link: 'https://www.vacances-lagrange.com/vacances-location-mer-montagne,logements-bretagne-et-loire-atlantique,saint-briac-sur-mer-dinard-STBRIA-L-ROCH-residence.html#residence'
  },
  {
    name: 'Hôtel de La Houle',
    category: 'Hôtel ***',
    address: '14 Boulevard de la Houle 35800 Saint Briac sur Mer',
    phone: '02 99 88 32 17',
    distance_km: '1.3km',
    distance_time: '4 minutes',
    link: 'https://www.hoteldelahoule.com/'
  },
  {
    name: 'Hôtel Le Nessay',
    category: 'Hôtel ****',
    address: 'Boulevard du Bechay 35800 Saint Briac sur Mer',
    phone: '02 99 21 02 10',
    distance_km: '1.3km',
    distance_time: '6 minutes',
    link: 'https://www.lenessay.com'
  },
  {
    name: 'Résidence Nemea Kermael',
    category: 'Hôtel ***',
    address: '19 Rue de Ker Gai 35800 Saint Briac sur Mer',
    phone: '05 40 24 60 59',
    distance_km: '1,9 km',
    distance_time: '5 minutes',
    link: 'https://www.residence-nemea.com/ete/france/bretagne/ille-et-vilaine/saint-briac-sur-mer/residence-vacances-kermael-23.html'
  },
  {
    name: 'Le Tertre Gatinais ',
    category: 'Gîte & Chambres d\'Hôtes',
    address: '7 chemin du Tertre Gatinais 35800 Saint Briac Sur Mer',
    phone: '06 50 20 07 53',
    distance_km: '1,9km',
    distance_time: '6 minutes',
    link: 'http://www.chambre-gite-briac.fr/'
  },
  {
    name: 'La Pensée',
    category: 'Gîte & Chambres d\'Hôtes',
    address: '35 Rue de la Grève 35800 Saint Lunaire',
    phone: '02 99 46 03 82',
    distance_km: '3,1 km',
    distance_time: '7 minutes',
    link: 'http://www.la-pensee.fr/'
  },
  {
    name: 'Cottage Santa Teresa',
    category: 'Chambres d\'hôtes',
    address: '29 Bd de Kerpezdron 35800 Saint Lunaire',
    phone: '06 48 61 49 68',
    distance_km: '3,2 km',
    distance_time: '8 minutes',
    link: 'https://cottage-santateresa.com/'
  },
  {
    name: 'La maison du Nessay ',
    category: 'Chambres d\'hôtes',
    address: '8 Rue de la Ville Assier 35800 Saint Briac sur Mer',
    phone: '02 99 21 02 10',
    distance_km: '3,6km',
    distance_time: '9 minutes',
    link: 'https://www.lenessay.com/fr/votre-sejour/a-la-maison-du-nessay/'
  },
  {
    name: 'Villa Christilla',
    category: 'Chambres d\'hôtes',
    address: '319 Boulevard de la plage 35800 Saint Lunaire',
    phone: '02 99 16 62 71',
    distance_km: '3,7 km',
    distance_time: '9 minutes',
    link: 'http://www.villa-christilla.fr/'
  },
  {
    name: 'Hôtel des Bains',
    category: 'Hôtel ***',
    address: '20 Rue du Poncel 22770 Lancieux',
    phone: '02 96 86 31 33',
    distance_km: '3,9km',
    distance_time: '9 minutes',
    link: 'http://hotel-des-bains-lancieux.fr/'
  },
  {
    name: 'EMERIA ',
    category: 'Hôtel ****',
    address: '1 Avenue du Château Hébert 35800 Dinard',
    phone: '02 99 16 78 10',
    distance_km: '7km',
    distance_time: '16 minutes',
    link: 'https://www.emeriadinard.com/fr/'
  },
  {
    name: 'Hotel de la Plage ',
    category: 'Hôtel ***',
    address: '3 Boulevard Féard 35800 Dinard',
    phone: '',
    distance_km: '7,8km',
    distance_time: '18 minutes',
    link: 'http://de-la-plage-35800.allbrittanyhotels.com/fr/'
  },
  {
    name: 'Hotel Didier Méril',
    category: 'Hotel ***',
    address: '1 Place du Général De Gaulle 35800 Dinard',
    phone: '02 99 46 95 74',
    distance_km: '10,2km',
    distance_time: '17 minutes',
    link: 'http://www.restaurant-didier-meril.com/hotel/'
  },
  {
    name: 'Le Grand Hôtel ',
    category: 'Hôtel *****',
    address: '46 Avenue Georges V 35800 Dinard',
    phone: '02 99 88 26 26',
    distance_km: '10,8km',
    distance_time: '17 minutes',
    link: 'https://www.hotelsbarriere.com/fr/dinard/le-grand-hotel.html'
  },
  {
    name: 'CastelBrac',
    category: 'Hotel*****',
    address: '17 Avenue Georges V 35800 Dinard',
    phone: '02 99 80 30 00',
    distance_km: '11km',
    distance_time: '20 minutes',
    link: 'https://www.castelbrac.com/fr/'
  },
  {
    name: 'Hôtel Printania',
    category: 'Hôtel ***',
    address: '5 Avenue Georges V 35800 Dinard',
    phone: '02 99 46 13 07',
    distance_km: '11,1km',
    distance_time: '20 minutes',
    link: 'https://www.printaniahotel.com/'
  }];
